import {
    CLIENT_PATHS,
    ClientPath,
    PageName,
    PATH_PARAMETER_SELECTORS,
    PATH_TO_MENU_ITEM_NAME_MAP,
    PATH_TO_PAGE_NAME_MAP
} from 'src/utils/routing/routes';
import RouteBuilder, { PathParameters } from 'src/utils/routing/RouteBuilder';
import { throwIfInvalidRouteOrQueryParameter } from 'src/utils/parameterValidation';

class ClientRouteBuilder {
    private readonly context: RouteBuilder;

    constructor({ context }: { context: RouteBuilder }) {
        this.context = context;
    }

    toHomePage = (): string => {
        return CLIENT_PATHS.HOME_PAGE;
    };

    toPartnerSelectionPage = (): string => {
        return CLIENT_PATHS.SELECT_PARTNER_PAGE;
    };

    toReportCategorySelectionPage = (
        {
            accessTypeCode = this.context.getAccessTypeCode(),
            partnerCode = this.context.getPartnerCode()
        }: PathParameters = {}
    ): string => {
        throwIfInvalidRouteOrQueryParameter({ accessTypeCode, partnerCode });

        return CLIENT_PATHS.SELECT_REPORT_CATEGORY_PAGE
            .replace(PATH_PARAMETER_SELECTORS.ACCESS_TYPE_CODE, accessTypeCode || '')
            .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || '');
    };

    toReportSelectionPage = (
        {
            accessTypeCode = this.context.getAccessTypeCode(),
            partnerCode = this.context.getPartnerCode(),
            reportCategoryCode = this.context.getReportCategoryCode()
        }: PathParameters = {}
    ): string => {
        throwIfInvalidRouteOrQueryParameter({ accessTypeCode, partnerCode, reportCategoryCode });

        return CLIENT_PATHS.DOWNLOAD_REPORTS_PAGE
            .replace(PATH_PARAMETER_SELECTORS.ACCESS_TYPE_CODE, accessTypeCode || '')
            .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || '')
            .replace(PATH_PARAMETER_SELECTORS.REPORT_CATEGORY_CODE, reportCategoryCode || '');
    };

    toDashboardSelectPartnerPage = (): string => {
        return CLIENT_PATHS.DASHBOARDS_SELECT_PARTNER_PAGE;
    };

    toDashboardSelectionPage = ({
        accessTypeCode = this.context.getAccessTypeCode(),
        partnerCode = this.context.getPartnerCode()
    }: PathParameters = {}): string => {
        throwIfInvalidRouteOrQueryParameter({ accessTypeCode, partnerCode });

        return CLIENT_PATHS.DASHBOARDS_SELECT_PAGE
            .replace(PATH_PARAMETER_SELECTORS.ACCESS_TYPE_CODE, accessTypeCode || '')
            .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || '');
    }

    toQuicksightPage = ({
        accessTypeCode = this.context.getAccessTypeCode(),
        partnerCode = this.context.getPartnerCode(),
        pinboardId = this.context.getPinboardId()
    }: PathParameters = {}): string => {
        throwIfInvalidRouteOrQueryParameter({ accessTypeCode, partnerCode, pinboardId });

        return CLIENT_PATHS.QUICKSIGHT_PAGE
        .replace(PATH_PARAMETER_SELECTORS.ACCESS_TYPE_CODE, accessTypeCode || '')
        .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || '')
        .replace(PATH_PARAMETER_SELECTORS.PINBOARD_ID, pinboardId || '');
    }

    toThoughtspotLiveBoardPage = ({
        accessTypeCode = this.context.getAccessTypeCode(),
        partnerCode = this.context.getPartnerCode(),
        pinboardId = this.context.getPinboardId()
    }: PathParameters = {}): string => {
        throwIfInvalidRouteOrQueryParameter({ accessTypeCode, partnerCode, pinboardId });

        return CLIENT_PATHS.THOUGHTSPOT_LIVEBOARD_PAGE
            .replace(PATH_PARAMETER_SELECTORS.ACCESS_TYPE_CODE, accessTypeCode || '')
            .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || '')
            .replace(PATH_PARAMETER_SELECTORS.PINBOARD_ID, pinboardId || '');
    }

    toRStudioLiveBoardPage = ({
        accessTypeCode = this.context.getAccessTypeCode(),
        partnerCode = this.context.getPartnerCode(),
        dashboardId = this.context.getDashboardId()
    }: PathParameters = {}): string => {
        throwIfInvalidRouteOrQueryParameter({ accessTypeCode, partnerCode, dashboardId });

        return CLIENT_PATHS.RSTUDIO_LIVEBOARD_PAGE
            .replace(PATH_PARAMETER_SELECTORS.ACCESS_TYPE_CODE, accessTypeCode || '')
            .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || '')
            .replace(PATH_PARAMETER_SELECTORS.DASHBOARD_ID, dashboardId || '')
    }

    toTableauPage = ({
        accessTypeCode = this.context.getAccessTypeCode(),
        partnerCode = this.context.getPartnerCode(),
        dashboardId = this.context.getDashboardId()
    }: PathParameters = {}): string => {
        throwIfInvalidRouteOrQueryParameter({ accessTypeCode, partnerCode, dashboardId });

        return CLIENT_PATHS.TABLEAU_PAGE
            .replace(PATH_PARAMETER_SELECTORS.ACCESS_TYPE_CODE, accessTypeCode || '')
            .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || '')
            .replace(PATH_PARAMETER_SELECTORS.DASHBOARD_ID, dashboardId || '')
    }

    toManageMarketingContentPage = (
        {
            partnerCode = this.context.getPartnerCode()
        }: PathParameters = {}
    ): string => {
        throwIfInvalidRouteOrQueryParameter({ partnerCode });

        return CLIENT_PATHS.MANAGE_MARKETING_CONTENT_PAGE
            .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || '');
    };

    toDashboardPartnerSelectionPage = (): string => {
        return CLIENT_PATHS.DASHBOARDS_SELECT_PARTNER_PAGE;
    }

    toMarketplacePage = (): string => {
        return CLIENT_PATHS.MARKETPLACE_PAGE;
    };

    toAdminPage = (): string => {
        return CLIENT_PATHS.ADMIN_PAGE;
    };

    toManagePartnersPage = (): string => {
        return CLIENT_PATHS.MANAGE_PARTNERS_PAGE;
    };

    toManageReportCategoriesPage = (): string => {
        return CLIENT_PATHS.MANAGE_REPORT_CATEGORIES_PAGE;
    };

    toManageFrequencyTypesPage = (): string => {
        return CLIENT_PATHS.MANAGE_FREQUENCY_TYPES_PAGE;
    };

    toManageExternalUsersPage = (): string => {
        return CLIENT_PATHS.MANAGE_EXTERNAL_USERS_PAGE;
    };

    toManageSaasUsersPage = ({ tenantId = this.context.getTenantId()}: PathParameters = {}): string => {
        return CLIENT_PATHS.MANAGE_SAAS_USERS_PAGE
            .replace(PATH_PARAMETER_SELECTORS.TENANT_ID, tenantId || '');
    };

    toManageSaasUserInvitationsPage = ({ tenantId = this.context.getTenantId() }: PathParameters = {}): string => {
        return CLIENT_PATHS.MANAGE_SAAS_USER_INVITATIONS_PAGE
            .replace(PATH_PARAMETER_SELECTORS.TENANT_ID, tenantId || '');
    };

    toManageFrequentlyAskedQuestionsPage = (): string => {
        return CLIENT_PATHS.MANAGE_FREQUENTLY_ASKED_QUESTIONS_PAGE;
    };

    toEditPartnerPage = (
        {
            accessTypeCode = this.context.getAccessTypeCode(),
            partnerCode = this.context.getPartnerCode()
        }: PathParameters = {}
    ): string => {
        throwIfInvalidRouteOrQueryParameter({ accessTypeCode, partnerCode });

        return CLIENT_PATHS.EDIT_PARTNER_PAGE
            .replace(PATH_PARAMETER_SELECTORS.ACCESS_TYPE_CODE, accessTypeCode || '')
            .replace(PATH_PARAMETER_SELECTORS.PARTNER_CODE, partnerCode || '');
    };

    toEditReportCategoryPage = (
        {
            reportCategoryCode = this.context.getReportCategoryCode()
        }: PathParameters = {}
    ): string => {
        throwIfInvalidRouteOrQueryParameter({ reportCategoryCode });

        return CLIENT_PATHS.EDIT_REPORT_CATEGORY_PAGE
            .replace(PATH_PARAMETER_SELECTORS.REPORT_CATEGORY_CODE, reportCategoryCode || '');
    };

    toEditFrequencyTypePage = (
        {
            frequencyTypeCode = this.context.getFrequencyTypeCode()
        }: PathParameters = {}
    ): string => {
        throwIfInvalidRouteOrQueryParameter({ frequencyTypeCode });

        return CLIENT_PATHS.EDIT_FREQUENCY_TYPE_PAGE
            .replace(PATH_PARAMETER_SELECTORS.FREQUENCY_TYPE_CODE, frequencyTypeCode || '');
    };

    toEditUserPage = (
        {
            userId = this.context.getUserId()
        }: PathParameters = {}
    ): string => {
        throwIfInvalidRouteOrQueryParameter({ userId });

        return CLIENT_PATHS.EDIT_USER_PAGE
            .replace(PATH_PARAMETER_SELECTORS.USER_ID, userId || '');
    };

    toEditSaasUserPage = (
        {
            userId = this.context.getUserId(),
            tenantId = this.context.getTenantId()
        }: PathParameters = {}
    ): string => {
        throwIfInvalidRouteOrQueryParameter({ userId });

        return CLIENT_PATHS.EDIT_SAAS_USER_PAGE
            .replace(PATH_PARAMETER_SELECTORS.USER_ID, userId || '')
            .replace(PATH_PARAMETER_SELECTORS.TENANT_ID, tenantId || '');
    };

    toEditFrequentlyAskedQuestionPage = (
        {
            frequentlyAskedQuestionId = this.context.getFrequentlyAskedQuestionId()
        }: PathParameters = {}
    ): string => {
        throwIfInvalidRouteOrQueryParameter({ frequentlyAskedQuestionId });

        return CLIENT_PATHS.EDIT_FREQUENTLY_ASKED_QUESTION_PAGE
            .replace(PATH_PARAMETER_SELECTORS.FREQUENTLY_ASKED_QUESTION_ID, frequentlyAskedQuestionId || '');
    };

    toCreatePartnerPage = (): string => {
        return CLIENT_PATHS.CREATE_PARTNER_PAGE;
    };

    toCreateReportCategoryPage = (): string => {
        return CLIENT_PATHS.CREATE_REPORT_CATEGORY_PAGE;
    };

    toCreateFrequencyTypePage = (): string => {
        return CLIENT_PATHS.CREATE_FREQUENCY_TYPE_PAGE;
    };

    toCreateUserPage = (): string => {
        return CLIENT_PATHS.CREATE_USER_PAGE;
    };

    toInviteSaasUserPage = ({ tenantId = this.context.getTenantId() }: PathParameters = {}): string => {
        return CLIENT_PATHS.INVITE_SAAS_USER_PAGE
            .replace(PATH_PARAMETER_SELECTORS.TENANT_ID, tenantId || '');
    };

    toCreateFrequentlyAskedQuestionPage = (): string => {
        return CLIENT_PATHS.CREATE_FREQUENTLY_ASKED_QUESTION_PAGE;
    };

    toFrequentlyAskedQuestionPage = (): string => {
        return CLIENT_PATHS.FREQUENTLY_ASKED_QUESTIONS_PAGE;
    };

    toReportAnIssuePage = (): string => {
        return CLIENT_PATHS.REPORT_AN_ISSUE_PAGE;
    };

    getPartialRouteFromUriParts = ({ index }: { index: number; }): string => {
        return this.joinPartsIncludingIndexOrDefaultToHomePage(this.context.uriParts, index);
    };

    getPageNameFromPathPartsAndUriParts = ({ index }: { index: number; }): string => {
        return this.getPageNameFromPath(
            this.joinPartsIncludingIndexOrDefaultToHomePage(this.context.pathParts, index)
        ) || this.context.uriParts[index];
    };

    getCurrentPageName = (): string | undefined => {
        return this.context.path && this.getPageNameFromPath(this.context.path);
    };

    getPageNameFromPath = (path: string): PageName | undefined => {
        return PATH_TO_PAGE_NAME_MAP[path as ClientPath];
    };

    getMenuItemNameForCurrentPage = (): string | undefined => {
        return this.context.path && this.getMenuItemNameFromPath(this.context.path);
    };

    getMenuItemNameFromPath = (path: string): string | undefined => {
        return PATH_TO_MENU_ITEM_NAME_MAP[path];
    };

    toCapitalOneHomePage = (): string => {
        return CLIENT_PATHS.CAPITAL_ONE_HOME_PAGE;
    };

    toMyAccountPage = (): string => {
        return CLIENT_PATHS.MY_ACCOUNT_PAGE;
    }

    toEditCustomerInvoicePage = ({ recordId = this.context.getFormRecordId() }: PathParameters): string => {
        throwIfInvalidRouteOrQueryParameter({ recordId });

        return CLIENT_PATHS.EDIT_CUSTOMER_INVOICE_PAGE
            .replace(PATH_PARAMETER_SELECTORS.FORM_RECORD_ID, recordId ?? '');
    };

    toEditWalmartDisputesPage = ({ recordId = this.context.getFormRecordId() }: PathParameters): string => {
        throwIfInvalidRouteOrQueryParameter({ recordId });

        return CLIENT_PATHS.EDIT_WALMART_DISPUTE_PAGE
            .replace(PATH_PARAMETER_SELECTORS.FORM_RECORD_ID, recordId ?? '');
    };

    private joinPartsIncludingIndexOrDefaultToHomePage = (parts: string[], index: number): string => {
        return parts.slice(0, index + 1).join('/') || CLIENT_PATHS.HOME_PAGE;
    };
}

export default ClientRouteBuilder;
