import { Button } from '@c1/gravity-react';
import { useEffect, useState } from 'react';
import RouteBuilder from 'src/utils/routing/RouteBuilder';
import { useRouteBuilder } from 'src/utils/routing/RouteBuilderContext';
import { FunctionComponentReturnType } from 'src/types/sharedReact';
import { GetDashboardResponse} from "../../types/apiResponse";
import { navigate, useLocation } from '@reach/router';
import "./TableauDashboard.css"

function TableauDashboard(): FunctionComponentReturnType {

    const [dashboard, setDashboard] = useState<GetDashboardResponse | null>(null);
    const routeBuilder: RouteBuilder = useRouteBuilder();
    const location = useLocation()

    let titleObject: any = !!location.state && typeof (location.state) === "object" ? location.state : { title: "RStudio View" };
    useEffect(() => {
        if (document.getElementById("page-template-title-element") && document.getElementById("page-template-title-element")!.textContent)
            document.getElementById("page-template-title-element")!.textContent = titleObject.title;
    }, []);

    function adjustIFrameHeight() {
        if (dashboard) {
            const iframe = document.getElementById('tableau-embed')
            iframe.style.height = window.innerHeight + 'px'
        }
    }

    window.addEventListener('load', adjustIFrameHeight);
    window.addEventListener('resize', adjustIFrameHeight)

    return (
        <div>
            <Button id={'copp-button-return'} className="grv-margin--tiny" type="regressive"
                    onClick={() => navigate(routeBuilder.client.toDashboardSelectionPage())} compact>Return</Button>
            <br/>
            <div>
                <text>Test Tableau Embed</text>
            </div>
        </div>
    );
}

export default TableauDashboard;
